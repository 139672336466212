@font-face {
	font-family: 'Grafier';
	src: url('./assets/Grafier/Grafier-RegularDisplay.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('./assets/Neue Machina/NeueMachina-Ultralight.woff2') format('woff2');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('./assets/Neue Machina/NeueMachina-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Neue Machina';
	src: url('./assets/Neue Machina/NeueMachina-Black.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
}
